var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body form-card" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "col-md-10" },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                class: _vm.errors.has("title_vi") ? "border-danger" : "",
                attrs: {
                  placeholder: "Tiêu đề",
                  "data-vv-name": "title_vi",
                  "data-vv-as": "Tiêu đề",
                },
                model: {
                  value: _vm.form.title_vi,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title_vi", $$v)
                  },
                  expression: "form.title_vi",
                },
              }),
              _vm.errors.has("title_vi")
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.errors.first("title_vi"))),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _vm._m(1),
          _c(
            "div",
            { staticClass: "col-md-10" },
            [
              _c("el-input", {
                attrs: { placeholder: "Tiêu đề (en)" },
                model: {
                  value: _vm.form.title_en,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title_en", $$v)
                  },
                  expression: "form.title_en",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _vm._m(2),
          _c(
            "div",
            { staticClass: "col-md-10" },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                class: _vm.errors.has("link") ? "border-danger" : "",
                attrs: {
                  placeholder: "Nhập đường dẫn",
                  "data-vv-name": "link",
                  "data-vv-as": "Đường dẫn",
                },
                model: {
                  value: _vm.form.link,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "link", $$v)
                  },
                  expression: "form.link",
                },
              }),
              _vm.errors.has("link")
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.errors.first("link"))),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        { staticClass: "page-action" },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("Quay lại")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("Lưu lại")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-2" }, [
      _c("b", { staticClass: "required" }, [_vm._v("Tiêu để")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-2" }, [
      _c("b", [_vm._v("Tiêu đề (en)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-2" }, [
      _c("b", { staticClass: "required" }, [_vm._v("Đường dẫn")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }